import { ContenedorGeneral } from "../Contenedor";
import styled from "styled-components";
import { Link } from "react-router-dom";

export default function PortadaVideo({ video, link }) {

    return (
        <ContenedorGeneral className=''>
            <SeccionCartel>
                <Link to={link}> <img className="imagen" src={video} alt="" /> </Link>
            </SeccionCartel>
        </ContenedorGeneral>

    )
}

const SeccionCartel = styled.div`
img{
   width:100%;
   height:100%;
   object-fit:cover;
   border-radius: 10px;
   border-bottom: solid 1px black; 
}
`