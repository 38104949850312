import React from "react";


function CanastasAnteriores( {lista1}){

  return(

    <>
      {lista1}

    </>
);
}

export default CanastasAnteriores;
