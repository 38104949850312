import { useState } from "react"
import styled from "styled-components"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { almacenarImagen } from "../../../../state/actions"

export default function ImageUploader({ token }) {
    let articuloEditar = useSelector((state) => state.articulo)
    const [image, setImage] = useState(articuloEditar.image)
    const [imageMessage, setImageMessage] = useState('')
    const dispatch = useDispatch();

    const uploadImage = async (e) => {

        try {
            const selectedFile = e.target.files[0]
            const formData = new FormData()
            formData.append('File', selectedFile)
            setImageMessage('(Cargando imagen)')

            await axios.post(`${process.env.REACT_APP_API_URL}articulo/imagen`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            })
                .then((response) => {
                    // Handle success
                    const { message, imageUrl } = response.data;
                    console.log("data imagen: ", response)
                    dispatch(almacenarImagen(imageUrl))
                    setImage(imageUrl)
                    console.log(`Success: ${message}`);
                })
        }
        catch (error) {
            console.log(`ERROR DE CARGA DE IMAGEN:${error} // ${error.response.data.message}`)
            setImageMessage('(Error en la carga de la imagen. Intente nuevamente.)')
        }

    }

    return (
        <ContainarImageUploader>
            <h5>Imagen:</h5>
            {!image ?
                <div>
                    <div className="archivos">
                        <p >-sólo archivos jpg, jpeg, png, webp-</p>
                        <p>{imageMessage}</p>
                    </div>
                    <input
                        type='file'
                        accept="image/*"
                        onChange={(e) => uploadImage(e)}
                    />
                </div>
                :
                <div>
                    <img src={image} className="imageToUpload" alt="" />
                </div>
            }
        </ContainarImageUploader>
    )
}

const ContainarImageUploader = styled.div`
.archivos{
    display:inline-block;
    font-size:15px;
    }
.imageToUpload{
    object-fit:cover;
    width: 80%;
    height: 17rem;

} 
    `