import { useState } from "react"
import { ContenedorGeneral } from "../../Contenedor"
import styled from "styled-components"
import axios from "axios"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { almacenarImagen, articuloEditado, mostrarArticulo } from "../../../../state/actions"
import ImageUploader from "./ImageUploader"

export default function Publicar() {
    let articuloEditar = useSelector((state) => state.articulo)
    let imagen = useSelector((state) => state.imagen)
    const [title, setTitle] = useState(articuloEditar.title)
    const [author, setAuthor] = useState(articuloEditar.author)
    const [content, setContent] = useState(articuloEditar.content)
    const [link, setLink] = useState(articuloEditar.link)
    const [Error, setError] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")
    const token = localStorage.getItem('token')
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const showError = (err) => {
        setError(true)
        setErrorMessage(err)
        setTimeout(() => { setError(false) }, 2000)
    }

    const cancelar = () => {
        setTitle('')
        setAuthor('')
        setContent('')
        setLink('')
        dispatch(almacenarImagen(''))
        articuloEditar = []
        dispatch(mostrarArticulo());
        dispatch(articuloEditado(articuloEditar));
        navigate('/articulos/publicados/' + token)

    }

    const handleSubmit = async (e) => {

        e.preventDefault()

        try {

            const formData = new FormData();
            formData.append('title', title);
            formData.append('author', author);
            formData.append('content', content);
            formData.append('link', link);
            formData.append('image', !imagen ? articuloEditar.image : imagen);


            if (!Array.isArray(articuloEditar)) //////MODIFICAR ARTÍCULO
            {

                await axios.put(`${process.env.REACT_APP_API_URL}articulo/${articuloEditar._id}`, formData, { headers })

            }

            else //////PUBLICAR ARTÍCULO
            {
                await axios.post(`${process.env.REACT_APP_API_URL}articulo/nuevo`, formData, { headers })
            }
            cancelar()

        }

        catch (error) {
            showError(error.response.data.message)
            console.log(`ERROR DE AXIOS: ${error}`)
        }
    }

    return (
        <PublicaciarArticulo>
            <form onSubmit={handleSubmit}>
                <ContenedorGeneral className="contenedorGeneral">
                    <div className="publicarArticulos">
                        <h5>Título:</h5>
                        <input
                            required={true}
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        /> <br />
                        <h5>Autor:</h5>
                        <input
                            required={true}
                            type="text"
                            value={author}
                            onChange={(e) => setAuthor(e.target.value)}
                        /> <br />
                        <ImageUploader
                            token={token}
                        />
                        <br />
                        <h5>Contenido:</h5>
                        <textarea
                            required={true}
                            id="formattedText" name="formattedText" rows="4" cols="50"
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                        ></textarea>
                        <br />
                        <input
                            placeholder="link al artículo original"
                            type="text"
                            value={link}
                            onChange={(e) => { setLink(e.target.value) }}
                        />
                    </div>
                    {Error && <div className="py-3">
                        <p>{errorMessage}</p>
                    </div>}
                    <div className="contenedor-botones">
                        <button
                            type='submit'
                        > {!Array.isArray(articuloEditar) ? 'Modificar' : 'Publicar'}
                        </button>
                        <button className="cancelar"
                            onClick={cancelar}
                            type="button"
                        >Cancelar</button></div>
                </ContenedorGeneral>
            </form>
        </PublicaciarArticulo>

    )
}


const PublicaciarArticulo = styled.div`
.contenedorGeneral{
    margin-top: 5%;
    justify-content:center;
    align-items:center;
    padding: 2% 5%;
    width: 60%;

}

.publicarArticulos{
    display: flex;
    flex-direction: column;
    justify-content:center;
    text-align:center;
    width: 80%;
}

button{
    margin-top:20px;
    border-radius:10px;
    background-color: #008900;
    color: white;
    padding: 0.3% 3%;
}
.contenedor-botones{
display: flex;
gap: 30px;
}

button:hover{
    background-color: red;
}


.archivos{
display:inline-block;
font-size:15px;
}
`
