import { ContenedorGeneral } from '../../Contenedor';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function NotaEspecial({ titulo, texto, autor, link, imagen }) {


    return (
        <ColumnaSemino>
            <Link className='link' to={link} >
                <ContenedorGeneral className='contenedor-nota-especial'>
                    <div className="contenedor-imagen">
                        <img src={imagen} alt="Imagen ilustrativa del artículo" />
                    </div>
                    <div className="texto-nota-especial">
                        <div  >
                            <h3>{titulo}</h3>
                            <br />
                            <h5>{texto}</h5>
                        </div>
                        <div className='autor-nota-especial'>
                            <h5>{autor}</h5>
                            <br />
                        </div>
                    </div>
                </ContenedorGeneral> </Link>
        </ColumnaSemino>
    )
}

const ColumnaSemino = styled.div`
.contenedor-nota-especial{
    flex-direction: column;
    justify-content:center;
    align-items:center;
    min-height: 16em;
    border-color:#FFAA00;
    color:white;
    background-color: #17130A;
    background:#262424;

}

.contenedor-imagen{
    border-radius: 10px 10px 0px 0px;
}

img{
    width:100%;
    object-fit:cover;
}

.texto-nota-especial{
    display: flex;
    max-witdh:100%;
    flex-direction: column;
    text-align:center;
    justify-content-center;
    overflow: hidden;
    font-size: 0.9em;
    padding-top: 1rem;
    padding-inline:3%;
    border-top: 1px solid white;
    color:rgba(255, 255, 255, 0.9);
    letter-spacing: 1.2px;
    line-height: 1.6;
}

h3{
    font-weight:bolder;
}

.autor-nota-especial{
    margin-top:1.5rem;
}

.link{
    text-decoration:none;
    color:white;
    margin-top:1.2em;
}

@media (min-width: 650px){
.contenedor-nota-especial{
    flex-direction: row;
    align-items: center;   
}

img{
    border-radius:10px;
    width: 60%;
}

.contenedor-imagen{
    border-radius:10px;
    width:40%;
}

.texto-nota-especial{
    border: none;
    border-left: 1px solid white;
    width:60%;

}
}
`