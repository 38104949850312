import styled from 'styled-components';
import ImagenPortada from '../../svg/portada3.svg';
import { Link } from "react-router-dom"
import iconoTeatro from '../../imagenes/iconoTeatro.png'
import iconoNoticias from '../../imagenes/iconoNoticias.png'
import iconoCanasta from '../../imagenes/iconoCanasta.png'
import iconoQuienes from '../../imagenes/iconoQuienes.png'
import iconoContacto from '../../svg/contacto.svg'
import iconoConsultorio from '../../imagenes/iconoConsultorio.png'
import iconoCapacitacion from '../../imagenes/iconoCapacitacion.png'
//import iconoTV from '../../svg/iconoTV.svg'


function PortadaHome() {
    return (

        <Encabezado >
            <Link to="/home"><img className="imagen" src={ImagenPortada} alt="Gerontovida" /></Link>
            <div className="contenedorIconos">
                <Link className="link-portada" to='/home/noticias'>
                    <div >
                        <img className='icono' src={iconoNoticias} alt="noticias" />
                        <p>noticias</p>
                    </div>
                </Link>
                <Link className="link-portada" to='/home/consultorio' >
                    <div>
                        <img className='icono' src={iconoConsultorio} alt="consultorio gerontológico" />
                        <p>consultorio</p>
                    </div>
                </Link>
                <Link className="link-portada" to='/home/capacitacion' >
                    <div>
                        <img className='icono' src={iconoCapacitacion} alt="capacitación" />
                        <p>cursos</p>
                    </div>
                </Link>
                <Link className="link-portada" to='/home/canasta' >
                    <div>
                        <img className='icono' src={iconoCanasta} alt="canasta" />
                        <p>canasta </p>
                    </div>
                </Link>
                <Link className="link-portada" to='/home/teatro'>
                    <div>
                        <img className='icono' src={iconoTeatro} alt="teatro" />
                        <p>teatro</p>
                    </div>
                </Link>
                <Link className="link-portada" to='/home/sobreGerontovida'>
                    <div>
                        <img className='icono icono-quienes' src={iconoQuienes} alt="quiénes somos" />
                        <p>quiénes </p>
                    </div>
                </Link>
                <Link className="link-portada" to='/home/contacto'>
                    <div>
                        <img className='icono' src={iconoContacto} alt="contacto" />
                        <p>contacto </p>
                    </div>
                </Link>
            </div>
        </Encabezado>
    );
}


export default PortadaHome;

const Encabezado = styled.div`
background-color: #EFEADD;
width:100%;
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;

border-bottom: solid 2px #0749E0;

.link-portada{
    text-decoration:none;
    color:black;
}

.imagen{
    width:360px;
    margin-top: 40px;
}

.contenedorIconos{
    display:none;

}

.icono{
width: 4rem;
margin-bottom:10px;
}



@media (min-width: 500px) {

    .imagen{
        width:32rem;
    } 
   }


@media (min-width: 650px) {

border-bottom:none;

.imagen{
    width:42rem;
} 



.contenedorIconos{

    display:flex;
    justify-content:center;
    border-top:solid 2px #0749E0;
    height: 50px;
    padding-top: 0.5rem;
    margin-bottom:6rem;
}

.contenedorIconos div{
    height: 85px;
    width: 85px;
    padding-block: 0.7rem;
    border-radius:50px;
    transition:background 0.3s;


}

.contenedorIconos div:hover{
    cursor:pointer;
    background: #008900;
    color:#008900;    
}
   
}

@media (min-width:1250px){
    flex-direction:row;
    border-bottom: solid 2px #0749E0;
    .contenedorIconos{
        border:none;
        margin-top:7rem;
    }

    .imagen{
        margin-left: -100px;
        margin-bottom: -40px;
    }  
}
   

`